import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import axios from "../../../../configAxiosPublic";

import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";

import { createCar, createReservation, editReservation } from "../_actions";
import { getVehicles } from "../../ClientProfile/_actions";

import dispatcher from "../_dispatcher";

import "./styles.css";
import moment from "moment";
import { getSingleReservation } from "../_actions";
import DatesLogic from "../Dates/DatesLogic";

const PaymentStripeLogic = () => {
    const { setBookingScreen, toggleHomePopUps, setBookingData, clearPromocode } = dispatcher();
    const dispatch = useDispatch();
    const history = useHistory();

    const aditional_payment = useSelector((state) => state.clientBooking.aditional_payment);

    const selectedCarId = useSelector((state) => state.clientBooking.reservation.vehicle_id) || "";
    const flightNumberName =
        useSelector((state) => state.clientBooking.reservation.flight_number) || "";

    const reservation_id = useSelector((state) => state.clientBooking.reservation.reservation_id);

    const isAuth = useSelector((state) => state.clientAuth.token_client);
    const vehicles = useSelector((state) => state.clientProfile.vehicles);
    const arrival_at = useSelector((state) => state.clientBooking.reservation.arrival_at);
    const departure_at = useSelector((state) => state.clientBooking.reservation.departure_at);
    const promocode = useSelector((state) => state.clientBooking.promocode);

    const [state, setState] = useState({
        flight_number: "",
        brand: "",
        model: "",
        plate: "",
    });

    const updateState = (e) => {
        // set_vehicle_id("");
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const clearFields = () => {
        setState({
            ...state,
            brand: "",
            model: "",
            plate: ""
        })
    }
    const [vehicle_id, set_vehicle_id] = useState("");
    const [isValid, setIsValid] = useState(false);

    const [showCreateCar, setShowCreateCar] = useState(false);

    useEffect(() => {
        const { flight_number, brand, model, plate } = state;
        if (!aditional_payment) {
            if (vehicle_id.length > 0 && flight_number.length > 0) {
                setIsValid(true);
            } else if (
                flight_number.length > 0 &&
                brand.length > 0 &&
                model.length > 0 &&
                plate.length > 0
            ) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
    }, [state, vehicle_id, aditional_payment]);

    useEffect(() => {
        if (reservation_id > 0 && aditional_payment) {
            getSingleReservation(reservation_id, dispatch).then((result) => {
                const { status, data } = result;
                if (status === 200) {
                    const selectedVehicle =
                        vehicles.find((item) => item.id === data.data.vehicle_id) || {};

                    const { brand, model, plate } = selectedVehicle;
                    setState({ ...state, flight_number: flightNumberName, brand, model, plate });
                    set_vehicle_id(selectedCarId);
                }
            });
        }
    }, [reservation_id, aditional_payment, flightNumberName]);

    const changeVehicle = (e) => {
        const { value } = e.target;
        const selectedVehicle = vehicles.find((item) => item.id === value) || {};
        const { brand, model, plate } = selectedVehicle;
        setState({
            ...state,
            brand,
            model,
            plate,
        });
        set_vehicle_id(value);
    };

    const [agree, toggleAgree] = useState(false);

    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(null);

    const stripe = useStripe();
    const elements = useElements();

    const options = {
        style: {
            base: {
                color: "#000",
                letterSpacing: "0",
                fontSize: "16px",
                fontWeight: "400",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    const handleChange = async (event) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const makePayment = async (client_secret) => {
        const payload = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
            },
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setSucceeded(true);
            axios
                .put("reservation-verify/" + payload.paymentIntent.id)
                .then((res) => {
                    const { status } = res;
                    if (status === 200) {
                        setProcessing(false);
                        setBookingScreen("active_step", 4);
                        // setBookingScreen("active_step", 4);
                    }
                    if (res.errors) {
                        setError(res.errors);
                    }
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    };

    const setData = (data) => {
        setBookingScreen("complete_second_step", true);
        setBookingScreen("enable_third_step", true);
        setBookingData({
            ...data,
            departure_at,
            arrival_at,
        });

        const { latest_payment } = data;
        const { client_secret } = latest_payment;
        makePayment(client_secret);
    };

    const onSubmit = async (data, { setErrors }) => {
        setProcessing(true);

        if (vehicle_id) {
            const { flight_number } = state;

            if (reservation_id) {
                const moderated = {
                    flight_number,
                    arrival_at: moment(arrival_at, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm"),
                    vehicle_id,
                    departure_at: moment(departure_at, "DD-MM-YYYY HH:mm").format(
                        "YYYY-MM-DD HH:mm"
                    ),
                    note: "",
                    reservation_id
                };
                editReservation(moderated, dispatch).then((response = {}) => {
                    if (response.status === 200) {
                        setData(response.data.reservation);
                    }
                    if (response.errors) {
                        setErrors(response.errors);
                    }
                });
            } else {
                const moderated = {
                    flight_number,
                    arrival_at,
                    vehicle_id,
                    departure_at,
                    note: "",
                    reservation_id,
                    promocode
                };
                createReservation(moderated, dispatch).then((response = {}) => {
                    if (response.status === 201) {
                        setData(response.data.data);
                        clearPromocode();
                    }
                    if (response.errors) {
                        setErrors(response.errors);
                    }
                });
            }
        } else {
            createCar(data, dispatch).then((response = {}) => {
                if (response.status === 201) {
                    const moderated = {
                        flight_number: data.flight_number,
                        arrival_at,
                        vehicle_id: response.data.data.id,
                        departure_at,
                        note: "",
                        // parking_id: 1,
                        reservation_id,
                        promocode
                    };
                    getVehicles(dispatch);
                    if (reservation_id) {
                        editReservation(moderated, dispatch).then((resp = {}) => {
                            if (resp.status === 200) {
                                setData(response.data.reservation);
                                clearPromocode();
                            }
                            if (resp.errors) {
                                setErrors(resp.errors);
                            }
                        });
                    } else {
                        createReservation(moderated, dispatch).then((resp = {}) => {
                            if (resp.status === 201) {
                                setData(resp.data.data);
                                clearPromocode();
                            }
                            if (resp.errors) {
                                setErrors(resp.errors);
                            }
                        });
                    }
                }
                if (response.errors) {
                    setErrors(response.errors);
                }
            });
        }
    };

    return {
        toggleHomePopUps,
        vehicles,
        agree,
        toggleAgree,
        vehicle_id,
        changeVehicle,
        state,
        updateState,
        options,
        onSubmit,
        processing,
        succeeded,
        error,
        handleChange,
        disabled,
        setBookingScreen,
        aditional_payment,
        isAuth,
        isValid,
        setShowCreateCar,
        showCreateCar,
        set_vehicle_id,
        clearFields
    };
};

export default PaymentStripeLogic;
