import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { Select, TextField } from "formik-material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Switch,
  FormControl,
  FormLabel,
  FormControlLabel,
  Box,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import CreateClientSchema from "../../../../_validations/CreateClientSchema";

import CreateLogic from "./CreateLogic";

import st from "./styles.module.scss";

const Create = () => {
  const { t } = useTranslation();
  const show = useSelector((state) => state.singleClient.showCreate);

  const {
    openClientCreate,
    state,
    onSubmit,
    loading,
    clearForm,
    active,
    toggleActive,
    type,
    toggleType,
    switchState,
    handleChange,
  } = CreateLogic();

  return (
    <>
      <div className={"pop  " + (show ? "show" : "")}>
        <div className={st.inner + " inner"}>
          <img
            className={st.close}
            src={ClosePopup}
            onClick={() => openClientCreate()}
          />
          <div className={st.summary}>
            <Formik
              enableReinitialize
              initialValues={state}
              validationSchema={CreateClientSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isValidating, isValid, dirty }) => {
                return (
                  <Form>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale="en-gb"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h3>
                            <strong>{t("clients.add_new_client")}</strong>
                          </h3>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-border mb-3">
                            <Field
                              name="first_name"
                              type="text"
                              label={t("clients.single.first_name")}
                              component={TextField}
                            ></Field>
                          </div>
                          <div className="input-border mb-3">
                            <Field
                              name="email"
                              type="text"
                              label={t("clients.single.email")}
                              component={TextField}
                            ></Field>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-border mb-3">
                            <Field
                              name="last_name"
                              type="text"
                              label={t("clients.single.last_name")}
                              component={TextField}
                            ></Field>
                          </div>
                          <div className="input-border mb-3">
                            <Field
                              name="phone"
                              type="text"
                              label={t("clients.single.phone")}
                              component={TextField}
                            ></Field>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                          <label className="mb-3">Car Info: </label>
                          <div className="col-md-6">
                            <div className="input-border mb-3">
                              <Field
                                name="brand"
                                type="text"
                                label={t("clients.single.brand")}
                                component={TextField}
                              ></Field>
                            </div>
                            <div className="input-border mb-3">
                              <Field
                                name="plate"
                                type="text"
                                label={t("clients.single.plate")}
                                component={TextField}
                              ></Field>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-border mb-3">
                              <Field
                                name="model"
                                type="text"
                                label={t("clients.single.model")}
                                component={TextField}
                              ></Field>
                            </div>
                          </div>
                        </div>
                    </MuiPickersUtilsProvider>
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-submit me-2"
                    >
                      {t("promocodes.single.submit_btn")}
                    </button>

                    <ButtonLoader show={loading} />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
