import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { Link } from "react-router-dom";

import ListTable from "../../../../components/ListTable/ListTable";

import ListLogic from "./ListLogic";

import st from "./styles.module.scss";
import Create from "./Create";

const List = () => {
    const { t } = useTranslation();

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);

    const { urlBase, list, openClientCreate } = ListLogic();

    return (
        <>
            {userPermitions["create_client"] && <Create />}
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("clients.title")} </h2>
                {userPermitions["create_client"] && (
                    <button
                        onClick={() => openClientCreate(null)}
                        type='button'
                        className='btn btn-submit'
                    >
                        {t("clients.add_new_client")}
                    </button>
                )}
            </div>
            <div className={st.list_table + " wrapper"}>
                <ListTable>
                    {list.map((item) => {
                        const fullName = item.first_name + " " + item.last_name;
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.country ? item.country.name : ""}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.total_reservations}</td>
                                <td>{item.total_sum_reservations} CHF</td>
                                <td>
                                    <div className={st.actions_icons}>
                                        {userPermitions["view_client"] && (
                                            <Link to={urlBase + item.id}>
                                                <i className='fas fa-pen'></i>
                                            </Link>
                                        )}
                                        <a href={"mailto:" + item.email}>
                                            <i className='fas fa-envelope'></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    {list.length === 0 && (
                        <tr>
                            <td colSpan={9} className='text-center'>
                                {t("clients.table.noResults")}
                            </td>
                        </tr>
                    )}
                </ListTable>
            </div>
        </>
    );
};

export default List;
